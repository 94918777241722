//get text from QR code
//splt text into prescription variables odp, odc, oda, osp, osc, osp, add
//setState for odp, odc, oda, osp, osc, osp, add to match imported data
//maybe Rx values are at end of https address but only make one web page that will parse out the ending to fill in the form

import {
  useParams
} from "react-router-dom";

  function PatientRx(props) {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();
    let splitter = id.split('&')
    console.log(splitter)

    let inputStyle={
      border: 'none',
      padding: '5px 8px 5px 0',
      margin: '2px',
      fontSize: '20px',
      width: '70px',
      borderRadius: '5px',
      background: 'rgb(242, 243, 244)',
      textAlign: 'center',
    }

    let pStyle={
      width: '80px',
      margin: '0px',
      fontWeight: '700',
      textAlign: 'center',
      fontSize: '20px',
    }

    let aStyle = {
      textDecoration: 'none',
      color: '#333',
      fontSize: '20px',
    }

    let aDivStyle = {
      margin: '0 0 10px 10px',
    }

    return (
      <div>
          <div style={{
            width: '100%',
            height:'100vh',
            margin: '0 0 0 5px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div>
            <h2 style={{
                textAlign: 'center',
                margin: '50px 0 10px 0',
              }}>GLASSES PRESCRIPTION
            </h2>
              <hr></hr>
              <div style={{
                 display: 'flex',
                 margin: '30px 0 0 0'
              }}>
                <p style={{
                  textAlign: 'left',
                  width: '115px',
                }}></p>
                <p style={pStyle} >Power</p>
                <p style={pStyle} >Cylinder</p>
                <p style={pStyle} >Axis</p>
              </div>
              <div style={{
                 display: 'flex',
              }}>
                <p style={{
                  textAlign: 'right',
                  width: '115px',
                  fontWeight: '700',
                  fontSize: '20px',
                  margin: '3px 0 0 3px',
                }}>Right (OD): </p>
                <p style={inputStyle}>
                  {splitter[0]}
                </p>
                <p style={inputStyle}>
                  {splitter[1]}
                </p>
                <p style={inputStyle}>
                  {splitter[2]}
                </p>
              </div>
              <div style={{
                 display: 'flex',
              }}>
                <p style={{
                  textAlign: 'right',
                  width: '115px',
                  fontWeight: '700',
                  fontSize: '20px',
                  margin: '3px 0 0 3px',
                }}>Left (OS): </p>
                <p style={inputStyle}>
                  {splitter[3]}
                </p>
                <p style={inputStyle}>
                  {splitter[4]}
                </p>
                <p style={inputStyle}>
                  {splitter[5]}
                </p>

              </div>
              {splitter[6] === ''
                ? <div></div>
                :
                <div style={{
                   display: 'flex',
                }}>
                  <p style={{
                    textAlign: 'right',
                    width: '115px',
                    fontWeight: '700',
                    fontSize: '20px',
                    margin: '3px 0 0 3px',
                  }}>Add: </p>
                  <p style={inputStyle}>
                    {splitter[6]}
                  </p>
                </div>
              }
              <div style={{
                 display: 'flex',
                 margin: '20px 0 30px 0'
              }}>
                <p style={{
                  textAlign: 'left',
                  fontSize: '20px',
                  margin: '15px 0 0 3px',
                }}>
                <strong>Pupillary distance:</strong> {splitter[7]} mm
              </p>
              </div>
              <hr></hr>
              <div
                style={{
                  margin: '30px 0 100px 3px',
                }}>
                <h2>
                  Online glasses retailers:
                </h2>
                <div style={aDivStyle}>
                  <a style={aStyle}
                    href='https://www.warbyparker.com/'
                    >
                    Warby Parker
                  </a>
                </div>
                <div style={aDivStyle}>
                  <a style={aStyle}
                    href='https://www.zennioptical.com/'
                    >
                    Zenni
                  </a>
                </div>
                <div style={aDivStyle}>
                  <a style={aStyle}
                    href='https://liingoeyewear.com/'
                    >
                    Liingo Eyeware
                  </a>
                </div>
                <div style={aDivStyle}>
                  <a style={aStyle}
                    href='https://www.eyebuydirect.com/'
                    >
                    EYEBUYDIRECT
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }

export default PatientRx
