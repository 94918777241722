function MonoVision(props) {

  if (props.monovision === true){
     return (
      <div>
        <div style = {{
            width: '100px',
            height: '46px',
            margin: '0px 0px 5px 100px',
            borderRadius: '23px',
            background: '#4cbb17',
          }}
            onClick={props.onMonoChange}>

          <div style= {{display: 'flex'}}>

            <p style = {{
              fontSize: '20px',
              color: '#fff',
              margin: '0px',
              padding: '12px 3px 3px 12px',
            }}>ON</p>

            <button
              style={{
              border: 'none',
              margin: '3px 3px 3px 10px',
              width: '40px',
              height: '40px',
              borderRadius: '20px',
              textAlign: 'center',
              background: '#fff',
            }}></button>

          </div>
        </div>
      </div>

    )
  }
  else if (props.monovision === false){
      return (
        <div>
          <div style = {{
              width: '100px',
              height: '46px',
              margin: '0px 0px 5px 100px',
              borderRadius: '23px',
              background: '#f2f3f4',
            }}
              onClick={props.onMonoChange}>

            <div style= {{display: 'flex',}}>

              <button
                style={{
                border: 'none',
                margin: '3px',
                width: '40px',
                height: '40px',
                borderRadius: '20px',
                textAlign: 'center',
                background: '#fff',
              }}></button>

              <p style = {{
                fontSize: '20px',
                color: 'darkgray',
                margin:'0px',
                padding: '12px 6px 3px 0px',
              }}>OFF</p>

            </div>

          </div>
        </div>

   )
  }
}

 export default MonoVision

//_____________________________________________
