function ContactSearch (props) {

  let optionsList;

  let ulStyle = {
    display: 'block',
    listStyle: 'none',
    transition: 'width 0.3s',
    margin: 'auto',
    position: 'relative',
  }

  let listFilterStyle = {
    display: 'block',
    margin: '3px 3px 3px 80px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: 'darkgray',
  }

  let listActiveStyle = {
    margin: '3px 3px 3px 80px',
    padding: '3px',
    fontSize: '1rem',
    width: '400px',
    borderRadius:'5px',
    color: '#000',
    fontWeight: '700',
  }

  let pStyle = {
    width: '80px',
    height: 'auto',
    textAlign: 'right',
    padding:'0px 5px 0px 0px',
    margin: '15px 0 0 0',
    fontSize:'18px',
    fontWeight:'700',
  }

  let inputStyle = {
    border: 'none',
    padding: '3px 3px 3px 20px',
    margin: '2px',
    fontSize:'18px',
    width: '400px',
    height:'40px',
    borderRadius:'5px',
    background:'#f2f3f4',
  }


  if (props.showOptions === true && props.searchString) {
    if (props.filteredOptions.length) {
      optionsList = (
        <ul style= {ulStyle} className = "options">
          {props.filteredOptions.map((optionName, index) => {
            let listName = listFilterStyle;
            if (index === props.activeOption) {
              listName = listActiveStyle;
            }
            return (
              <li style= {listName} key= {optionName.name} onClick= {() =>
                props.onContactClick(optionName)}>
                  {optionName.name}
              </li>
            );
          })}
        </ul>
      );
    } else {
      optionsList = (
        <div style= {listActiveStyle}>
          <em>No Options!</em>
        </div>
      );
    }
  }

  return(
    <div>
      <div style= {{
          margin:'0',
          textAlign: 'left',
          position: 'relative',
        }}>
        <div style= {{
            display:'flex',
          }}>
          <p style= {pStyle}>OD: </p>
          <input
            type="text"
            style={inputStyle}
            onChange={props.onUserInput}
            onKeyDown= {props.onKeyDown}
            value={props.searchString}
            />
        </div>
        {optionsList}
      </div>
    </div>
  );
}


export default ContactSearch
