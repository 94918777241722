function GlassesReadingOnly(props) {

  return(
    <div>
      <br></br>
      <div style={{
          display: 'flex',
        }}>
          <p style={{
          textAlign: 'right',
          fontWeight: '700',
          margin: '0',
        }}>Glasses for knitting, long reads, electronics, or fly-tying</p>
      </div>
      <div style={{
         display: 'flex',
      }}>
        <p style={{
          textAlign: 'right',
          margin: '0',
        }}>Right: {props.powerR} {props.cylinderR} {props.axisR}</p>
      </div>
      <div style={{
         display: 'flex',
      }}>
        <p style={{
          textAlign: 'right',
          margin: '0',
        }}>Left: <span style={{marginLeft: '11px'}}></span>{props.powerL} {props.cylinderL} {props.axisL}</p>


      </div>
    </div>
  )
}




export default GlassesReadingOnly
