function ContactsToric (props) {

   let rxStyle={
     margin: '0'
   }

   if(props.contactPicked.type === 'toric' && props.cyl <= -0.75 && props.axis !== 'check axis' && props.monovision === true){

      return(
        <div style={rxStyle}>
          <p style= {{
              margin: '0',
              padding: '0',
            }}>Right: {props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} {props.cyl} {props.axis} / Replace every {props.contactPicked.replace} / monovision (for near)
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.cylWarning}</span>
          </p>
        </div>

      )
   }

   else if (props.contactPicked.type === 'toric' && props.cyl <= -0.75 && props.axis !== 'check axis'){
     return(
       <div style={rxStyle}>
         <p style= {{
             margin: '0',
             padding: '0',
           }}>Right: {props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} {props.cyl} {props.axis} / Replace every {props.contactPicked.replace}
           <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
           <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.cylWarning}</span>
         </p>
       </div>

     )
   }

   else if (props.contactPicked.type === 'toric'){
     return(
       <div>
         <p style= {{
             margin: '0',
             color: 'red',
           }}>Please check prescription, including cylinder and axis</p>
       </div>

     )
   }

   else {
     return(
       <div></div>
     )
   }

}

export default ContactsToric
