function ContactsSphericalL (props) {

   let rxStyle={
     margin: '0'
   }

   if(props.contactPicked.type === 'spherical' && props.addPower*1 > 0 && props.monovision === true) {

      return(
        <div style={rxStyle}>
          <p style= {{
              margin: '0',
              padding: '0',
            }}>Left: <span style={{marginLeft: '11px'}}></span>{props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} / Replace every {props.contactPicked.replace} / monovision (for near)
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
          </p>
        </div>

      )
   }

   else if (props.contactPicked.type === 'spherical'){
     return(
       <div style={rxStyle}>
         <p style= {{
             margin: '0',
             padding: '0',
           }}>Left: <span style={{marginLeft: '11px'}}></span>{props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} / Replace every {props.contactPicked.replace}
           <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
         </p>
       </div>

     )
   }

   else {
     return(
       <div></div>
     )
   }

}

export default ContactsSphericalL
