function ContactsMultifocal (props) {

   let rxStyle={
     margin: '0'
   }

   if(props.contactPicked.type === 'multifocal' && props.addPower*1 > 0) {

      return(
        <div style={rxStyle}>
          <p style= {{
              margin: '0',
              padding: '0',
            }}>Right: {props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} / Add {props.add} / Replace every {props.contactPicked.replace}
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
          </p>
        </div>

      )
   }

   else if (props.contactPicked.type === 'multifocal'){
     return(
       <div>
         <p style= {{ margin: '0', color: 'red',}}> Right: Please check prescription, including add power</p>
       </div>
     )
   }

   else {
     return(
       <div></div>
     )
   }

}

export default ContactsMultifocal
