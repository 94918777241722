import CustomSelectThree from "./CustomSelectThree"


function FormInput(props){


  let inputStyle={
    border: 'none',
    padding: '3px 3px 3px 40px',
    margin: '2px',
    fontSize: '18px',
    width: '100px',
    height: '40px',
    borderRadius: '5px',
    background: 'rgb(242, 243, 244)',
  }

  let pStyle={
    width: '147px',
    margin: '0px',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '18px',
  }

  return(

  <div>
    <div style={{
      width: '100%',
    }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <h2>REFRACTION</h2>
          <button
            style={{
              fontSize: '2em',
              width: '200px',
              height: '50px',
              textDecoration: 'none',
              border: '1px solid #007bff',
              borderRadius: '5px',
              color: '#007bff',
              background: '#FFF',
              cursor: 'pointer',
            }}
            onClick={() => window.location = '/'}
            >
            Reset form
          </button>
        </div>
          <div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
              }}></p>
              <p style={pStyle} >Power</p>
              <p style={pStyle} >Cylinder</p>
              <p style={pStyle} >Axis</p>
            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>OD: </p>
              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: ['Plano']},
                  {rowPlace: 'left', data: minusPowers},
                  {rowPlace: 'right', data: plusPowers},
                ]}
                hook={props.powerR}
                setHook={props.setPowerR}
                selectWidth='146'
              />

              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: ['Sphere']},
                  {rowPlace: 'left', data: cylMinusPowers},
                  {rowPlace: 'right', data: cylPlusPowers},
                ]}
                hook={props.cylinderR}
                setHook={props.setCylinderR}
                selectWidth='146'
              />

              {
                props.cylinderR === 'Sphere'
                ? 
                  <div
                    style={{
                      border: 'none',
                      padding: '3px 10px',
                      margin: '2px',
                      fontSize: '18px',
                      width: '135px',
                      height: '40px',
                      borderRadius: '5px',
                      background: '#FFF',
                      textAlign:'center',
                    }}
                  >
                  </div>
                : 
                  <input
                    style={inputStyle}
                    value={props.axisR}
                    onChange={e => {
                      const value = e.target.value;
                      // Ensure the value is a whole number between 1 and 180
                      if (/^\d*$/.test(value) && (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 180))) {
                        props.setAxisR(value);
                      }
                    }}
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    min="1"
                    max="180"
                    step="1"
                  />
              }

              
            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>OS: </p>
              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: ['Plano']},
                  {rowPlace: 'left', data: minusPowers},
                  {rowPlace: 'right', data: plusPowers},
                ]}
                hook={props.powerL}
                setHook={props.setPowerL}
                selectWidth='146'
              />

              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: ['Sphere']},
                  {rowPlace: 'left', data: cylMinusPowers},
                  {rowPlace: 'right', data: cylPlusPowers},
                ]}
                hook={props.cylinderL}
                setHook={props.setCylinderL}
                selectWidth='146'
              />

              {
                props.cylinderL === 'Sphere'
                ? 
                  <div
                    style={{
                      border: 'none',
                      padding: '3px 10px',
                      margin: '2px',
                      fontSize: '18px',
                      width: '135px',
                      height: '40px',
                      borderRadius: '5px',
                      background: '#FFF',
                      textAlign:'center',
                    }}
                  >
                  </div>
                : 
                <input
                  style={inputStyle}
                  value={props.axisL}
                  onChange={e => {
                    const value = e.target.value;
                    // Ensure the value is a whole number between 1 and 180
                    if (/^\d*$/.test(value) && (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 180))) {
                      props.setAxisL(value);
                    }
                  }}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                  min="1"
                  max="180"
                  step="1"
                />
              
              }

            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>Add: </p>
              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: addPowers},
                ]}
                hook={props.addPower}
                setHook={props.setAddPower}
                selectWidth='146'
              />
            </div>
            <div style={{
               display: 'flex',
               margin: '50px 0 0 0',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>PD (mm): </p>
              <input
                style={inputStyle}
                name="pd"
                value={props.pd}
                onChange={(e) => props.setPd(e.target.value)}
              />
              <div style={{
                 display: 'flex',
                 margin: '0px 0 0 0',
              }}>
                <p style={{
                  textAlign: 'right',
                  width: '110px',
                  fontWeight: '700',
                  fontSize: '18px',
                  margin: '15px 0 0 38px',
                }}>Vertex (mm): </p>
                <input
                  style={inputStyle}
                  name="vertexDistance"
                  value={props.vertexDistance}
                  onChange={(e) => props.setVertexDistance(e.target.value)}
                />
              </div>
            </div>
          </div>
      </div>
  </div>
  )
}

export default FormInput


const minusPowers = [
  '-0.25',
  '-0.50',
  '-0.75',
  '-1.00',
  '-1.25',
  '-1.50',
  '-1.75',
  '-2.00',
  '-2.25',
  '-2.50',
  '-2.75',
  '-3.00',
  '-3.25',
  '-3.50',
  '-3.75',
  '-4.00',
  '-4.25',
  '-4.50',
  '-4.75',
  '-5.00',
  '-5.25',
  '-5.50',
  '-5.75',
  '-6.00',
  '-6.25',
  '-6.50',
  '-6.75',
  '-7.00',
  '-7.25',
  '-7.50',
  '-7.75',
  '-8.00',
  '-8.25',
  '-8.50',
  '-8.75',
  '-9.00',
  '-9.25',
  '-9.50',
  '-9.75',
  '-10.00',
  '-10.25',
  '-10.50',
  '-10.75',
  '-11.00',
  '-11.25',
  '-11.50',
  '-11.75',
  '-12.00',
  '-12.25',
  '-12.50',
  '-12.75',
  '-13.00',
  '-13.25',
  '-13.50',
  '-13.75',
  '-14.00',
  '-14.25',
  '-14.50',
  '-14.75',
  '-15.00',
  '-15.25',
  '-15.50',
  '-15.75',
  '-16.00',
  '-16.25',
  '-16.50',
  '-16.75',
  '-17.00',
  '-17.25',
  '-17.50',
  '-17.75',
  '-18.00',
  '-18.25',
  '-18.50',
  '-18.75',
  '-19.00',
  '-19.25',
  '-19.50',
  '-19.75',
  '-20.00',
  '-20.25',
  '-20.50',
  '-20.75',
  '-21.00',
  '-21.25',
  '-21.50',
  '-21.75',
  '-22.00',
  '-22.25',
  '-22.50',
  '-22.75',
  '-23.00',
  '-23.25',
  '-23.50',
  '-23.75',
  '-24.00',
  '-24.25',
  '-24.50',
  '-24.75',
  '-25.00',
  '-25.25',
  '-25.50',
  '-25.75',
  '-26.00',
  '-26.25',
  '-26.50',
  '-26.75',
  '-27.00',
  '-27.25',
  '-27.50',
  '-27.75',
  '-28.00',
  '-28.25',
  '-28.50',
  '-28.75',
  '-29.00',
  '-29.25',
  '-29.50',
  '-29.75',
  '-30.00',
]

const plusPowers = [
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const cylPlusPowers = [
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const cylMinusPowers = [
    '-0.25',
    '-0.50',
    '-0.75',
    '-1.00',
    '-1.25',
    '-1.50',
    '-1.75',
    '-2.00',
    '-2.25',
    '-2.50',
    '-2.75',
    '-3.00',
    '-3.25',
    '-3.50',
    '-3.75',
    '-4.00',
    '-4.25',
    '-4.50',
    '-4.75',
    '-5.00',
    '-5.25',
    '-5.50',
    '-5.75',
    '-6.00',
    '-6.25',
    '-6.50',
    '-6.75',
    '-7.00',
    '-7.25',
    '-7.50',
    '-7.75',
    '-8.00',
    '-8.25',
    '-8.50',
    '-8.75',
    '-9.00',
    '-9.25',
    '-9.50',
    '-9.75',
    '-10.00',
]

const addPowers = [
  '',
  '+2.50',
  '+2.25',
  '+2.00',
  '+1.75',
  '+1.50', 
  '+1.25',
  '+1.00',
  '+0.75',
  '+0.50',
  '+0.25',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
]