import React from 'react';

export default function SelectColumn(props) {

  const handleMouseEnter = (m) => {
    const element = document.getElementById(`${m}`);
    if (element) {
      element.style.background = '#FFF';
      element.style.fontWeight = '500';
    }
  };

  const handleMouseLeave = (m) => {
    const element = document.getElementById(`${m}`);
    if (element) {
      element.style.background = '#e6f3f8';
      element.style.fontWeight = '400';
    }
  };

  const handleClick = (m) => {
    props.setHook(m);
    props.setHookClick(false);
  };

  return (
    <div style={{ margin: '0 0 0 0px' }}>
      {props.data.map(m => (
        <div
        key={m === '' ? 'empty' : m}
        id={`${m === '' ? 'empty' : m}`}
        onClick={() => handleClick(m)}
        onMouseEnter={() => handleMouseEnter(m === '' ? 'empty' : m)}
        onMouseLeave={() => handleMouseLeave(m === '' ? 'empty' : m)}
        style={{ 
          padding: '0',
          fontSize: '18px',
          }}
      >
        {m === '' ? 'No add' : m}
      </div>
      ))}
    </div>
  );
}
