import GlassesDistance from './GlassesDistance'
import GlassesDistanceNear from './GlassesDistanceNear'
import GlassesComputerNear from './GlassesComputerNear'
import GlassesDeskWork from './GlassesDeskWork'
import GlassesReadingOnly from './GlassesReadingOnly'

function Glasses(props) {

  // powerR
  // if props.cylinderR is negative, powerR = props.powerR + props.cylinderR
  // if props.powerR is plano or pl, powerR is 0
  // if props.cylinderR is positive, powerR = props.powerR
  let powerR = (props.cylinderR*1 < 0 && (props.powerR === 'plano' || props.powerR === 'pl'))
    ? props.cylinderR*1
    : (props.cylinderR*1 < 0 && (props.powerR > 0 || props.powerR < 0))
      ? props.powerR*1 + props.cylinderR*1
      : ((props.cylinderR*1 >= 0 || props.cylinderR === "sphere" || props.cylinderR === "sph")&& (props.powerR > 0 || props.powerR < 0))
        ? props.powerR*1
        : ((props.cylinderR*1 >= 0 || props.cylinderR === "sphere" || props.cylinderR === 'sph') && (props.powerR === 'plano' || props.powerR === 'pl' || props.powerR === '0'))
          ? 0
          : (props.powerR === '' && props.cylinderR === '' && props.axisR === '')
            ? ''
            : 'x'

    // powerL
    // if props.cylinderL is negative, powerL = props.powerL + props.cylinderL
    // if props.powerL is plano or pl, powerL is 0
    // if props.cylinderL is positive, powerL = props.powerL
    let powerL = (props.cylinderL*1 < 0 && (props.powerL === 'plano' || props.powerL === 'pl'))
      ? props.cylinderL*1
      : (props.cylinderL*1 < 0 && (props.powerL > 0 || props.powerL < 0))
        ? props.powerL*1 + props.cylinderL*1
        : ((props.cylinderL*1 >= 0 || props.cylinderL === "sphere" || props.cylinderL === "sph")&& (props.powerL > 0 || props.powerL < 0))
          ? props.powerL*1
          : ((props.cylinderL*1 >= 0 || props.cylinderL === "sphere" || props.cylinderL === 'sph') && (props.powerL === 'plano' || props.powerL === 'pl' || props.powerL === '0'))
            ? 0
            : (props.powerL === '' && props.cylinderL === '' && props.axisL === '')
              ? ''
              : 'x'


  //cylinderR
  // if props.cylinderR is sphere or sph, cylinderR is 0
  // otherwise, cylinderR is an abolute value
  let cylinderR = (props.cylinderR === 'sphere' || props.cylinderR === 'sph' || props.cylinderR === 0)
    ? "sphere"
    : (props.cylinderR > 0 || props.cylinderR < 0)
      ? '+' + Math.abs(props.cylinderR*1).toFixed(2)
      : '';


  //cylinderL
  // if props.cylinderL is sphere or sph, cylinderL is 0
  // otherwise, cylinderL is an abolute value
  let cylinderL = (props.cylinderL === 'sphere' || props.cylinderL === 'sph' || props.cylinderL === 0)
    ? "sphere"
    : (props.cylinderL > 0 || props.cylinderL < 0)
      ? '+' + Math.abs(props.cylinderL*1).toFixed(2)
      : '';


  // axisR
  // if props.cylinderR is < 0 then ask
  // if props.axisR is between 0 and 90 then axisR adds 90 to props.axisR
  // if props.axisR is between 91 and 180 then axisR subtracts 90 from props.axisR
  // if props.cylinderR is > 0 then axisR = props.axisR
  let axisR = (props.cylinderR*1 < 0)
    ? (props.axisR*1 >=0 && props.axisR*1 <= 90)
      ? 'x' + (props.axisR*1 + 90)
      : (props.axisR*1 >= 91 && props.axisR*1 <=180)
        ? 'x' + (props.axisR*1 - 90)
        : "Check Axis"
    : !(props.cylinderR > 0 || props.cylinderR < 0)
      ? ''
      : 'x' + props.axisR*1;


  // axisL
  // if props.cylinderL is < 0 then ask
  // if props.axisL is between 0 and 90 then axisL adds 90 to props.axisL
  // if props.axisL is between 91 and 180 then axisL subtracts 90 from props.axisL
  // if props.cylinderL is > 0 then axisL = props.axisL
  let axisL = (props.cylinderL*1 < 0)
    ? (props.axisL*1 >=0 && props.axisL*1 <= 90)
      ? 'x' + (props.axisL*1 + 90)
      : (props.axisL*1 >= 91 && props.axisL*1 <=180)
        ? 'x' + (props.axisL*1 - 90)
        : "Check Axis"
    : !(props.cylinderL > 0 || props.cylinderL < 0)
      ? ''
      : 'x' + props.axisL*1;


  //distancePowerR
  let distancePowerR = (powerR === '')
    ? ''
    :(powerR === "x")
      ? 'Check Power and Cylinder'
      : (powerR === 0)
        ? "Plano"
        : (powerR > 0)
          ? '+' + powerR.toFixed(2)
          : powerR.toFixed(2)


  //distancePowerL
  let distancePowerL = (powerL === '')
    ? ''
    : (powerL === "x")
      ? 'Check Power and Cylinder'
      : (powerL === 0)
        ? "Plano"
        : (powerL > 0)
          ? '+' + powerL.toFixed(2)
          : powerL.toFixed(2)


  //deskwork powerR
  let deskWorkPowerR = (powerR === '')
    ? ''
    : (powerR === "x")
      ? 'Check Power and Cylinder'
      : ((props.addPower*1 >= '0.25' && props.addPower*1 <= '1.50') && (powerR + props.addPower*1) === 0)
        ? "Plano"
        : (props.addPower*1 >= '0.25' && props.addPower*1 <= '1.50')
          ? ((powerR + props.addPower*1) > 0)
            ? '+' + (powerR + props.addPower*1).toFixed(2)
            : (powerR + props.addPower*1).toFixed(2)
          : "Check Add Power";

  //deskwork powerL
  let deskWorkPowerL = (powerL === '')
    ? ''
    : (powerL === "x")
      ? 'Check Power and Cylinder'
      : ((props.addPower*1 >= '0.25' && props.addPower*1 <= '1.50') && (powerL + props.addPower*1) === 0)
        ? "Plano"
        : (props.addPower*1 >= '0.25' && props.addPower*1 <= '1.50')
          ? ((powerL + props.addPower*1) > 0)
            ? '+' + (powerL + props.addPower*1).toFixed(2)
            : (powerL + props.addPower*1).toFixed(2)
          : "Check Add Power";



  // computerPowerR
  //If props.addPower is greater than 1.5 then computerPowerR is powerR + props.addPower - 1.
  let computerPowerR = (powerR === '')
    ? ''
    : (powerR === "x")
      ? 'Check Power and Cylinder'
      : ((props.addPower*1 > '1.50') && (powerR + props.addPower*1 -1) === 0)
        ? "Plano"
        : ((powerR + props.addPower*1 -1) > 0)
          ? '+' + (powerR + props.addPower*1 -1).toFixed(2)
          : (powerR + props.addPower*1 -1).toFixed(2)

  // computerPowerL
  //If props.addPower is greater than 1.5 then computerPowerL is powerL + props.addPower - 1.
  let computerPowerL = (powerL === '')
    ? ''
    : (powerL === "x")
      ? 'Check Power and Cylinder'
      : ((props.addPower*1 > '1.50') && (powerL + props.addPower*1 -1) === 0)
        ? "Plano"
        : ((powerL + props.addPower*1 -1) > 0)
          ? '+' + (powerL + props.addPower*1 -1).toFixed(2)
          : (powerL + props.addPower*1 -1).toFixed(2)


  // readingPowerR
  // if props.addPower is greater than 2.00 then readingPowerR is powerR + props.addPower
  // else "Check Add Power"
   let readingPowerR = (powerR === '')
     ? ''
     :(powerR === "x")
       ? 'Check Power and Cylinder'
       : ((props.addPower*1 >= 2.00) && (powerR + props.addPower*1) === 0)
        ? "Plano"
        : (props.addPower*1 >= 2.00)
          ? ((powerR + props.addPower*1) > 0)
           ? '+' + (powerR + props.addPower*1).toFixed(2)
           : (powerR + props.addPower*1).toFixed(2)
          : "Check Add Power";

  // readingPowerL
  // if props.addPower is greater than 2.00 then readingPowerL is powerL + props.addPower
  // else "Check Add Power"
   let readingPowerL = (powerL === '')
     ? ''
     : (powerL === "x")
       ? 'Check Power and Cylinder'
       : ((props.addPower*1 >= 2.00) && (powerL + props.addPower*1) === 0)
        ? "Plano"
        : (props.addPower*1 >= 2.00)
          ? ((powerL + props.addPower*1) > 0)
           ? '+' + (powerL + props.addPower*1).toFixed(2)
           : (powerL + props.addPower*1).toFixed(2)
          : "Check Add Power";


  return (
    <div>
      {(props.powerR === '' && props.cylinderR === '' && props.axisR === '' && props.powerL === '' && props.cylinderL === '' && props.axisL === '')
        ? <div></div>
        : (props.addPower === '' || props.addPower*1 === 0)
        ? <div>
            <GlassesDistance
              powerR={distancePowerR}
              cylinderR={cylinderR}
              axisR={axisR}
              powerL={distancePowerL}
              cylinderL={cylinderL}
              axisL={axisL}
            />
          </div>
        : (props.addPower >0 && props.addPower <=1.5)
            ? <div>
                <GlassesDistanceNear
                  powerR={distancePowerR}
                  cylinderR={cylinderR}
                  axisR={axisR}
                  powerL={distancePowerL}
                  cylinderL={cylinderL}
                  axisL={axisL}
                  addPower={props.addPower}
                />
                <GlassesDeskWork
                  powerR={deskWorkPowerR}
                  cylinderR={cylinderR}
                  axisR={axisR}
                  powerL={deskWorkPowerL}
                  cylinderL={cylinderL}
                  axisL={axisL}
                />
                <GlassesDistance
                  powerR={distancePowerR}
                  cylinderR={cylinderR}
                  axisR={axisR}
                  powerL={distancePowerL}
                  cylinderL={cylinderL}
                  axisL={axisL}
                  />

                </div>
              : (props.addPower > 1.50 && props.addPower < 2.00)
                ? <div>
                    <GlassesDistanceNear
                      powerR={distancePowerR}
                      cylinderR={cylinderR}
                      axisR={axisR}
                      powerL={distancePowerL}
                      cylinderL={cylinderL}
                      axisL={axisL}
                      addPower={props.addPower}
                    />
                    <GlassesComputerNear
                      powerR={computerPowerR}
                      cylinderR={cylinderR}
                      axisR={axisR}
                      powerL={computerPowerL}
                      cylinderL={cylinderL}
                      axisL={axisL}
                      addPower='+1.00'
                    />
                    <GlassesDistance
                      powerR={distancePowerR}
                      cylinderR={cylinderR}
                      axisR={axisR}
                      powerL={distancePowerL}
                      cylinderL={cylinderL}
                      axisL={axisL}
                    />
                  </div>
                : (props.addPower >= 2.00)
                  ? <div>
                      <GlassesDistanceNear
                        powerR={distancePowerR}
                        cylinderR={cylinderR}
                        axisR={axisR}
                        powerL={distancePowerL}
                        cylinderL={cylinderL}
                        axisL={axisL}
                        addPower={props.addPower}
                      />
                      <GlassesComputerNear
                        powerR={computerPowerR}
                        cylinderR={cylinderR}
                        axisR={axisR}
                        powerL={computerPowerL}
                        cylinderL={cylinderL}
                        axisL={axisL}
                        addPower='+1.00'
                      />
                      <GlassesReadingOnly
                        powerR={readingPowerR}
                        cylinderR={cylinderR}
                        axisR={axisR}
                        powerL={readingPowerL}
                        cylinderL={cylinderL}
                        axisL={axisL}
                      />
                      <GlassesDistance
                        powerR={distancePowerR}
                        cylinderR={cylinderR}
                        axisR={axisR}
                        powerL={distancePowerL}
                        cylinderL={cylinderL}
                        axisL={axisL}
                      />
                    </div>
                  : ''
                }


    </div>

  )

}

export default Glasses
