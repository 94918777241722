function ContactsToricMultifocal (props) {

   let rxStyle={
     margin: '0'
   }

   if(props.contactPicked.type === 'toricMultifocal' && props.cyl <= -0.75 && props.addPower*1 > 0 && props.axis !== 'check axis') {

      return(
        <div style={rxStyle}>
          <p style= {{
              margin: '0',
              padding: '0',
            }}>Right: {props.contactPicked.name} / BC: {props.contactPicked.bc} / {props.power} {props.cyl} {props.axis} / Add {props.add} / Replace every {props.contactPicked.replace}
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.powerWarning}</span>
            <span style = {{ margin: '0 0 0 20px',color: 'red',}}>{props.cylWarning}</span>
          </p>
        </div>

      )
   }

   else if (props.contactPicked.type === 'toricMultifocal'){
     return(
       <div>
         <p style= {{ margin: '0', color: 'red',}}> Right: Please check prescription, including cylinder, axis, and add power</p>
       </div>
     )
   }

   else {
     return(
       <div></div>
     )
   }

}

export default ContactsToricMultifocal
